import React from 'react';
import {Helmet} from "react-helmet";
import './App.css';

function App() {

  return (
    <>
      <Helmet htmlAttributes>
        <html lang="en" />
        <title>MashLabs Decentralized Artist Discovery</title>
        <meta name="description" content="MashLabs Decentralized Artist Discovery" />
      </Helmet>
      <div className="App" style={{ height: "100vh", width: "100vw", position: "relative" }}>
        <iframe
          src="https://preview.mailerlite.io/preview/333890/sites/117095977688499256/ejHHZ6"
          style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", border: "none" }}
        />
        <header className="App-header">
          {/* This header will be hidden behind the iframe */}
          <img style={{ width: "80%" }} src="/images/Mash_Highlight_Still.png" alt="Mash Highlight" />
        </header>
      </div>
    </>
  );
}

export default App;
